.blogs-view-wrap {
}

.blogs-view {

}

.blogs-view__main-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100dvh - 78px); 
    margin-bottom: 100px;
}

.blogs-view__main-text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.blogs-view__main-text {
    max-width: 380px;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: 0.96px;
}

.blogs-view__main-sub-text {
    max-width: 500px;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.44px;
    color: var(--greyColor2);
}

.blogs-view__main-blog-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 100px;
}

.blogs-view__main-blog-img {
    width: 100%;
    height: auto;
}

.blogs-view__main-blog-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
}

.blogs-view__main-blog-text {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.22px;
    color: var(--greyColor2);
}

.blogs-view__blog--wrap {
    display: flex;
    flex-direction: column;
    gap: 65px;
    margin-bottom: 100px;
}

.blogs-view__blog-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.blogs-view__blog-img {
    width: 100%;
    height: auto;
}

.blogs-view__blog-img-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
}

.blogs-view__blog-img-text {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.22px;
}

@media (min-width: 640px) {
    .blogs-view__main-text {
        max-width: 455px;
        font-size: 59px;
        font-style: normal;
        line-height: normal;
    }
    .blogs-view__blog--wrap {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 150px;
    }
    .blogs-view__blog-wrap {
        width: 48%;
        margin-bottom: 60px;
    }
    .blogs-view__img {
        max-height: 420px;
    }
    .blogs-view__blog-img-text,
    .blogs-view__main-blog-text {
        font-size: 16px;
        line-height: normal;
        letter-spacing: 0.32px;
    }
}

@media (min-width: 960px) {
    .blogs-view__main-text {
        max-width: 700px;
        font-size: 79px;
        line-height: 120px;
    }
    .blogs-view__main-sub-text {
        max-width: 840px;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 46px;
        letter-spacing: 0.6px;
    }
    .blogs-view__blog--wrap {
        gap: 40px;
        margin-bottom: 200px;
    }
    .blogs-view__blog-wrap {
        width: 47%;
        margin-bottom: 80px;
    }
    .blogs-view__main-blog-title {
        font-size: 30px;
        line-height: normal;
    }
    .blogs-view__main-blog-text {
        font-size: 20px;
    }
}

@media (min-width: 1200px) {
    .blogs-view__main-text {
        max-width: 875px;
    }
    .blogs-view__main-blog-title {
        font-size: 36px;
    }
}

@media (min-width: 1440px) {
    .blogs-view__main-text {
        font-size: 96px;
    }
}