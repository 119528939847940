.form-block-wrap {
    width: 100%;
    background: #fff;
}

.form-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 40px 20px;
}

.form-block__img-wrap {
    position: relative;
    width: fit-content;
    margin-bottom: 30px;
    transform: translateX(-42px);
}

.form-block__img {
    max-width: 240px;
    width: 100%;
    height: auto;
    display: none;
}

.form-block__img-talk {
    width: 100%;
    max-width: 320px;
    height: auto;
}

.form-block__text-talk{
    font-size: 18px;
    position: absolute;
    top: 65px;
    left: 160px;
    color: #000;
    animation: textMove 6s ease infinite;
}

.form-block__form-wrap {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
}

.form-block__form-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.48px;
    color: #0F0F0F;
    margin-bottom: 15px;
}

.form-block__form-input-wrap {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.form-block__form-input-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.28px;
    color: #3D3D3D;
}

.form-block__form-input {
    font-size: 14px;
    background: #EEE;
    border-radius: 0 !important;
    font-family: 'Unbounded' !important;
}

.form-block__form-input.MuiInputBase-multiline {
    padding: 0;
}

.form-block__form-input::placeholder {
    font-family: 'Unbounded' !important;
}

.form-block__form-input .MuiInputBase-input {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: #000000;
    padding: 7.5px 14px;
}

.form-block__form-input .MuiOutlinedInput-notchedOutline {
    border: none;
}

.form-block__error-text {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

.form-block__form-input.error {
    border: 1px solid red;
}

.form-block__form-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 180px;
    height: 40px;
    background: var(--darkColor);
    color:  var(--laightColor);
    margin-left: auto;
    transition: 0.3s;
}

.form-block__form-btn:hover {
    background-color: var(--laightColor);
    outline: 1px solid var(--darkColor) ;
    color: var(--darkColor);
    transition: 0.3s;
}

.form-block__text-wrap {
    display: none;
    position: relative;
}

.form-block__text {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
    color: var(--darkColor);
    animation: textMove 7s ease infinite !important;
}

@keyframes textMove {
    0% { transform: translate(0, 0); } 
    25% { transform: translate(30%, -75%); } 
    37.5% { transform: translate(25%, -65%); } 
    50% { transform: translate(32%, -77%); } 
    75% { transform: translate(0, 0); } 
    87.5% { transform: translate(4%, -10%); }
    100% { transform: translate(0, 0); } 
}

.blog-view__notification--wrap{
    background-color: #bfbfbf;
    width: 552px;
    height: 281px;
    text-align: center;
    position: fixed; 
    top: 50%;       
    left: 50%;    
    transform: translate(-50%, -50%); 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.blog-view__notification-title{ 
    font-size: 32px;
    line-height: 56px;
    letter-spacing: 3%;
    font-weight: 700;
    color: #0F0F0F;
}

.blog-view__notification-wrap{
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #0F0F0F;
    gap: 20px;
}
.blog-view__notification-text{
    font-size: 20px;
    font-weight: 400;
    line-height: 24.8px;
    letter-spacing: 2%;
}

.blog-view__notification-btn{
    width: 180px;
    height: 40px;
    color: #0F0F0F;
    background-color: #fff;
}

@media (min-width: 640px) {
    .form-block {
        flex-direction: row-reverse;
        justify-content: space-between;
        gap: 20px;
        padding: 100px 20px;
    }   
    .form-block__img-wrap {
        transform: translateX(0);
    }
    .form-block__text-wrap {
        display: block;
        min-width: 150px;
    }
    .form-block__text{
        animation: textMove 4s ease-in-out infinite !important;
    }
    .form-block__form-wrap {
        max-width: 320px;
    }
    .form-block__img-talk {
        display: none;
    }
    .MuiInputBase-input {
        font-size: 14px;
        padding: 10px 14px;
    }
    .form-block__img{
        display: flex;
    }
    .form-block__text-talk{
        display: none;
    }
}

@media (min-width: 960px) {
    .form-block__form-wrap {
        max-width: 400px;
    }
    .form-block__form-wrap {
        max-width: 400px;
    }
    .form-block__text{
        font-size: 33px;
    }
}