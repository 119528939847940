.showcase-view-wrap {
}

.showcase-view {

}

.showcase-view__main-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100dvh - 78px); 
    margin-bottom: 100px;
}

.showcase-view__main-text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.showcase-view__main-text {
    max-width: 380px;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 68px; 
    letter-spacing: 1.2px;
}

.showcase-view__main-sub-text {
    max-width: 500px;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.44px;
    color: var(--greyColor2);
}

.showcase-view__img--wrap {
    display: flex;
    flex-direction: column;
    gap: 65px;
    margin-bottom: 100px;
}

.showcase-view__img-wrap {
    position: relative;
    border: 2px solid var(--lightColor);
}

.showcase-view__img {
    width: 100%;
    height: 100%;
}

.showcase-view__img-text {
    position: absolute;
    top: 100%;
    left: 15px;
    color: var(--darkColor);
    background: var(--lightColor);
    padding: 5px 13px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.13px;
}

.showcase-view__img-wrap--tranform {
}

@media (min-width: 640px) {
    .showcase-view__main-text {
        max-width: 455px;
        font-size: 59px;
        line-height: 91px;
    }
    .showcase-view__img---wrap{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 80px;
    } 
    .showcase-view__img-wrap {
        width: 100%;
        margin-bottom: 35px;
    }
    .showcase-view__img--wrap-padding {
        padding-top: 200px;
    }
    .showcase-view__img {
        max-height: 420px;
    }
    .showcase-view__img-wrap--tranform {
        transform: translateY(60px);
    }
    .showcase-view__img-text {
        padding: 6px 20px;
    }
}

@media (min-width: 960px) {
    .showcase-view__main-text {
        max-width: 600px;
        font-size: 79px;
        line-height: 120px;
    }
    .showcase-view__main-sub-text {
        max-width: 840px;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 46px;
        letter-spacing: 0.6px;
    }
    .showcase-view__img--wrap {
        gap: 60px;
        margin-bottom: 200px;
    }
    .showcase-view__img-text {
        padding: 8px 20px;
    }
}

@media (min-width: 1200px) {
    .showcase-view__main-text {
        max-width: 875px;
        font-size: 100px;
        line-height: normal;
    }
}

@media (min-width: 1440px) {
    .showcase-view__main-text {
        font-size: 120px;
    }
}