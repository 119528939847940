.footer-wrap {
    padding: 40px 0;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
}

.footer__logo-wrap {

}

.footer__logo {
    width: 70px;
    height: auto;
}

.footer__menu-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer__menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.footer__menu-item {
    list-style: none;
}

.footer__menu-link {

}

.footer__address-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer__title {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.18px;
    color: var(--greyColor);
    margin-bottom: 10px;
}

.footer__text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.22px;
}

.footer__address {
    text-align: center;
}

.footer__link-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 60px;
}

.footer__link-email-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer__text-email {
    white-space: nowrap;
}

.footer__link-follow-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer__link-follow-links-wrap {

}

.footer__link-follow-links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 27px;
    margin-top: 5px;
}

.footer__link-follow-link-item {
    list-style: none;
}

.footer__link-follow-link {
}

.footer__link-follow-link-img {
    max-width: 57px;
    min-width: 40px;
    width: 100%;
    height: auto;
}

@media (min-width: 640px) {
    .footer-wrap {
        padding: 27px 0 40px;
    }
    .footer {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 30px;
    }
    .footer__logo-wrap {
        display: none;
    }
    .footer__address-wrap,
    .footer__link-wrap,
    .footer__menu-wrap {
        flex: 1 1;
        align-items: flex-start;
    }
    .footer__menu,
    .footer__link-follow-wrap,
    .footer__link-email-wrap {
        align-items: flex-start;
    }
    .footer__address {
        text-align: left;
    }
    .footer__link-wrap {
        max-width: 265px;
    }
    .footer__menu-wrap {
        order: 1;
        max-width: fit-content;
    }
    .footer__address-wrap {
        order: -1;
        max-width: 170px;
    }
    .footer__link-follow-links {
        gap: 20px;
    }
}

@media (min-width: 960px) {
    .footer__link-follow-links {
        gap: 27px;
    }
    .footer__text {
        font-size: 20px;
    }
    .footer__address-wrap {
        max-width: 225px;
    }
}

@media (min-width: 1440px) {
    .footer__address-wrap {
        max-width: 241px;
    }
}