.not-found-wrap {
    height: 40vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.not-found {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.not-found__number {
    font-size: 60px;
    font-weight: 600;
}

.not-found__text {
    font-size: 20px;
}

@media (min-width: 960px) {
   
}
