.contact-view-wrap {
    padding-top: 78px;
}

.contact-view {

}

.contact-view__main-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
    margin-bottom: 120px;
}

.contact-view__main-text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.contact-view__main-text {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 68px; 
    letter-spacing: 0.84px;
    white-space: nowrap;
}

@media (min-width: 640px) {
    .contact-view__main-text {
        font-size: 30px;
        line-height: 45px;
    }
    .contact-view__main-wrap {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}

@media (min-width: 960px) {
    .contact-view__main-text {
        font-size: 50px;
        line-height: 70px;
    }
}

@media (min-width: 1200px) {
    .contact-view__main-text {
        font-size: 65px;
        line-height: normal;
    }
}

@media (min-width: 1440px) {
    .contact-view__main-text {
        font-size: 76px;
    }
}