.blog-bottom {
    border-top: 2px solid rgba(255, 255, 255, 0.25); 
    margin-top: 80px;
    padding-top: 50px;
}

.blog-bottom__info-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 65px;
    margin-bottom: 90px;
}

.blog-bottom__info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.blog-bottom__info-img {
    max-width: 90px;
    width: 100%;
    height: auto;
    border: 2px solid #fff;
    border-radius: 50%;
}

.blog-bottom__info-title-wrap {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.blog-bottom__info-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
}

.blog-bottom__info-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 10px;
    letter-spacing: 0.28px;
    color: var(--greyColor);
}

.blog-bottom__links--wrap {
    display: flex;
    align-items: center;
    gap: 17px;
}

.blog-bottom__links-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
    color: var(--greyColor);
}

.blog-bottom__links-wrap {

}

.blog-bottom__links {
    display: flex;
    align-items: center;
    gap: 35px;
}

.blog-bottom__link-item {
    list-style: none;
}

.blog-bottom__link {

}

.blog-bottom__link-img {
    width: 40px;
    height: auto;
}

.blog-bottom__slider-title {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.6px;
    margin-bottom: 30px;
}

.blog-bottom__slider {

}

.blog-bottom__slider-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    width: 100%;
}

.blog-bottom__slider-img {
    width: 100%;
    height: 210px;
}

.blog-bottom__slider-item-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    text-align: left;
}

.blog-bottom__slider-item-text {
    font-size: 14px;
    font-style: normal;
    margin-top: 10px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.28px;
    color: var(--greyColor2);
}

@media (min-width: 640px) {
    .blog-bottom__info-wrap {
        flex-direction: row;
        justify-content: space-between;
    }
}

@media (min-width: 960px) {
    .blog-bottom__info-wrap {
        margin-bottom: 130px;
    }
    .blog-bottom__info-img {
        max-width: 70px;
    }
}