.layout {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-width: 100vw;
    max-height: 100dvh;
    min-height: 100dvh;
    overflow: hidden;
    z-index: 100;
}

.layout__main-wrap {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
}

.layout__outlet-wrap {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
}

.layout__create-btn {
    position: fixed;
    bottom: 20px;
    right: 40px;
    z-index: 100;
    transition: transform 0.3s ease;
}

.layout__create-btn-img {
    width: 100px;
    height: 100px;
    border: 1px solid #fff;
    border-radius: 50%;
    background: #fff;
    transition: transform 0.4s ease;
}

.layout__create-btn--hover:hover {
    transform: scale(1.1);
    animation: hoverAnimation 0.8s ease-in-out;
}

.layout__create-btn--hover:hover .layout__create-btn-circle {
    fill: var(--darkColor);
}

.layout__create-btn--hover:hover .layout__create-btn-path {
    fill: #fff;
}

@keyframes hoverAnimation {
    0% {
        transform: scale(1) rotate(0deg);
    }
    25% {
        transform: scale(1.1) rotate(20deg);
    }
    50% {
        transform: scale(1.1) rotate(-20deg);
    }
    75% {
        transform: scale(1.1) rotate(10deg);
    }
    100% {
        transform: scale(1.1) rotate(0deg);
    }
}

.layout__create-btn--menu {
    bottom: 75px;
    animation: moveAndRotate 3s infinite linear;
}

@keyframes moveAndRotate {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    22% {
        transform: translate(-150px, 0) rotate(50deg);
    }
    66% {
        transform: translate(0, -150px) rotate(-50deg);
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}

@media (min-width: 960px) {
    .layout {
        padding-bottom: 0;
    }
    .layout__create-btn--menu {
        bottom: 20px;
        animation: moveAndRotate 8s infinite linear;
    }
    @keyframes moveAndRotate {
        0% {
            transform: translate(0, 0) rotate(0deg);
        }
        33% {
            transform: translate(-300px, 0) rotate(40deg);
        }
        66% {
            transform: translate(0, -300px) rotate(-40deg);
        }
        100% {
            transform: translate(0, 0) rotate(0deg);
        }
    }
}

@media (min-width: 1440px) {
    .layout__create-btn-img {
        width: 140px;
        height: 140px;
    }
}
