.project-view-wrap {
    overflow-x: hidden;
}

.project-view {
    padding-bottom: 80px;
}

.project-view__main-wrap {
    position: relative;
    margin-bottom: 150px;
}
.project-view__main-text-wrap{
    position: relative;
}

.project-view__main-text {
    max-width: 380px;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: 0.96px;
    padding-top: 20px;
    padding-left: 20px;
}

.project-view__main-img {
    width: 100%;
    height: auto;
}

.project-view__description-wrap {

}

.project-view__description {

}

.project-view__title {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.6px;
    margin-bottom: 30px;
}

.project-view__text {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
    letter-spacing: 0.22px;
    margin-bottom: 15px;
    color: var(--greyColor3);
}

.project-view__text-width{
    max-width: 100%;
}

.project-view__description-img {
    width: auto;
    max-width: 90%;
    max-height: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 85px;
    margin-bottom: 50px;
}

.project-view__sub-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.32px;
}

.project-view__stack-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 40px;
}

.project-view__block-wrap {
    display: flex;
    flex-direction: column;
    gap: 70px;
    margin-top: 150px;
}

.project-view__block {
}

.project-view__block-img {
}

.project-view__slider-img {
    width: 100%;
    height: auto;
}

.project-view__review {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 55px;
    margin-bottom: 30px;
}

.project-view__review-img {
    width: 90px;
    height: auto;
}

.project-view__review-text-wrap {

}

.project-view__review-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
}

.project-view__review-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
    color: var(--greyColor);
}

.project-view__description-img {
    width: auto;
    max-width: 90%;
    max-height: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 85px;
    margin-bottom: 50px;
}


.project-view__approach {
    margin-top: 150px;
    margin-bottom: 150px;
}

.project-view__sliderBottom{
    margin-top: 25px;
}

.project-view__approach-text--wrap {

}

.project-view__approach-text-wrap {
    
}

.project-view__review-text--desktop {
    display: none;
}

.project-view__review-text--mob {

}

.project-view__result-img {
    width: 100%;
    height: auto;
    margin-top: 70px;
    margin-bottom: 100px;
}

.project-view__swiper-title {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.4px;
    opacity: 0.3;
    font-weight: 700;
    text-align: center;
    margin-top: 100px;
}


.project-view__video-container{
    width: 100vw;
    margin-left: -20px;
}

.project-view__slider{
    width: 100%;
    display: flex;
    margin-top: -15px;
}

.project-view__swiper-container{
    width: 100%;
    padding-bottom: 100px;
}
.project-view__slider-desktop{
    display: none;
}
.project-view__slider-mobile{
    margin-top: -20px;
}
.project-view__slider-bodybuilder--desktop{
    display: none;
}

.project-view__slider-bodybuilder--mobile{
    margin-top: -20px;
}

.project-view__description-img--desktop {
    display: none;
    width: auto;
    max-width: 100%;
    max-height: 500px;
}

.project-view__text-padding{
    margin-left: 15px;
}



@media (min-width: 640px) {
    .project-view__stack-wrap {
        justify-content: space-around;
    }
    .project-view__main-text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 300px;
        font-size: 42px;
        max-width: none;
        margin-bottom: -12px;
    }
    .project-view__main-text-black{
        color: #000;
    }
    .project-view__title {
        font-size: 48px;
        letter-spacing: 0.96px;
    }
    .project-view__text {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.4px;
    }
    .project-view__sub-title {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.4px;
    }
    .project-view__video-container{
        width: 100vw;
    }
    .project-view__swiper-title{
        font-size: 65px;
    }
    .project-veiew__swiper-container--mobile{
        display: none;
    }
    .project-view__slider{
        display: none;
        margin-top: -20px;
    }
    .project-view__slider-mobile{
        display: none !important;
    }
    .project-view__slider-desktop {
        display: flex;
        flex-wrap: wrap;
        gap: 20px; 
        justify-content: space-between !important;
        margin-top: -24px !important;
    }
    .project-view__slider-img--desktop {
        width: calc(50% - 10px);
        height: auto;
        object-fit: cover; 
        object-position: center;
    }
}

@media (min-width: 768px) {
    .project-view__description-img {
        display: none;
    }
    .project-view__description-img--desktop {
        display: block;
        margin-right: auto;
    }
    .project-view__description-desktop{
        min-width: 48%;
    }
    .project-view__description-wrap {
        display: flex;
        justify-content: space-between;
        gap: 30px;
    }
    .project-view__block,
    .project-view__description {
        min-width: 48%;
    }
    .project-view__block-wrap {
        flex-direction: row;
        gap: 30px;
    }
    .project-view__block-img {
        width: auto;
        max-height: 500px;
        max-width: 100%;
        margin-right: auto;
    }
    .project-view__approach-text--wrap {
        display: flex;
        gap: 30px;
    }
    .project-view__approach-text-wrap {
        flex-direction: row;
        flex: 1 1;
    }
    .project-view__result-text--wrap{
        display: flex;
        gap: 30px;
    }
    .project-view__result-text-wrap{
        flex: 1 1;
    }
    .project-view__text--last {
        flex: 1 1;
        width: 48%;
    }
    .project-view__review-img {
        width: auto;
        min-width: 200px;
    }
    .project-view__review-text--mob {
        display: none;
    }
    .project-view__review-text--desktop {
        display: block;
    }
    .project-view__main-text {
        font-size: 50px;
        margin-bottom: -10px;
    }
    .project-view__slider-coach--desktop {
        margin-top: -40px !important;
    }
    .project-view__slider-bodybuilder--desktop{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
        margin-top: -25px;
    }
    .project-view__bodybuilder-img{
        width: 22.9%;
    }
    .project-view__slider-bodybuilder--mobile{
        display: none !important;
    }
    .project-view__text-width{
        max-width: 48%;
    }
}

@media (min-width: 960px) {
    .project-view__main-text {
        font-size: 66px;
        line-height: normal;
        margin-bottom: -16px;
        padding-left: 80px;
    }
    .project-view__review-img {
        min-width: 300px;
    }
    .project-view__video-container{
        width: 100vw;
        margin-left: -80px;
    }
    .project-view__swiper-title{
        font-size: 100px;
    }
    .project-view__slider{
        margin-top: -45px;
    }
    .project-view__slider-bodybuilder--desktop{
        margin-top: -40px;
    }
    .project-view__bodybuilder-img{
        width: 23%;
    }
}

@media (min-width: 1200px) {
    .project-view__main-text {
        font-size: 82px;
        margin-bottom: -20px;
    }
    .project-view__swiper-title{
        font-size: 120px;
    }
    .project-view__video-container{
        width: 100vw;
        margin-left: -80px;
    }
    .project-view__slider{
        margin-top: -45px;
    }
    .project-view__slider-desktop {
        margin-top: -43px !important;
    }
    .project-view__slider-bodybuilder--desktop{
        margin-top: -45px;
    }
    .project-view__bodybuilder-img{
        width: 23.5%;
    }
}

@media (min-width: 1440px) {
    .project-view__video-container{
        width: 100%;
        margin-top: 50px;
        max-width: 1440px;
        margin: 0;
        margin-top: 50px;
    }
    .project-view__main-text{
        margin-bottom: -20px;
    }
    .project-view__bodybuilder-img{
        width: 23.8%;
    }
}