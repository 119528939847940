.header-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
    padding: 0 20px !important;
}

.header__logo {
    width: 100%;
    max-width: 100px;
    height: auto;
    max-height: 137px;
}

.header__side-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    z-index: 7;
}

.header__side-bar-link-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: fit-content;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 15px 20px;
    overflow: auto;
}

.header__side-bar-link-wrap::-webkit-scrollbar {
    display: none;
}

.header__side-bar-link--wrap {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding-right: 15px;
}

.header__side-bar-link {
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.44px;
    color: black;
    text-shadow:
        -1px -1px 0 white,  
        1px -1px 0 white,
        -1px 1px 0 white,
        1px 1px 0 white;
    transition: 0.3s;
}

.header__side-bar-link:hover {
    color: #fff;
    box-shadow: none;
    transform: translateX(30px);
}

.header__menu {
    position: relative;
    z-index: 100;
    cursor: pointer;
    margin-bottom: 4px;
}

.header__menu-bar1,
.header__menu-bar2, 
.header__menu-bar3 {
    width: 35px;
    height: 5px;
    background-color: #fff;
    margin: 6px 0;
    transition: 0.4s;
}

.header__menu-bar2 {
    width: 57%;
    margin-left: auto;
}

.header__menu--active .header__menu-bar1 {
    transform: translate(0, 11px) rotate(-45deg);
}

.header__menu--active .header__menu-bar2 {opacity: 0;}

.header__menu--active .header__menu-bar3 {
    transform: translate(0, -11px) rotate(45deg);
}

.header__side-bar-link.active {
    color: #fff !important;
    transform: translateX(0) !important;
    text-shadow: none;
}


.header__link-follow-links-wrap {
    align-self: flex-start;
    margin-top: 20px;
}

.header__link-follow-links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;
    margin-top: 5px;
}

.header__link-follow-link-item {
    list-style: none;
}

.header__link-follow-link {
}

.header__link-follow-link-img {
    max-width: 57px;
    min-width: 40px;
    width: 100%;
    height: auto;
}

@media (min-width: 640px) {
    .header {
        padding: 0 80px 0 65px !important;
    }
    .header__side-bar-link {
        font-size: 55px;
    }
    .header__link-follow-links {
        gap: 45px;
    }
}

@media (min-width: 960px) {
    .header__side-bar-link {
        font-size: 65px;
    }
    .header__link-follow-links {
        gap: 70px;
    }
}

@media (min-width: 1200px) {
    .header__side-bar-link {
        font-size: 75px;
    }
}