/* @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap');

body {
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    position: relative;
    font-family: 'Unbounded';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    overflow: hidden;
    padding: 0;
    background: rgba(15, 15, 15, 1);
    color: #FEFEFE;
    /* touch-action: ''; */
    /* -ms-touch-action: ''; */
    /* -webkit-text-size-adjust: 100%; */
}

:root {
    --darkColor: rgba(15, 15, 15, 1);
    --lightColor: #FEFEFE;
    --greyColor: #878787;
    --greyColor2: #CFCFCF;
    --greyColor3: #BDBDBD;
    --redColor: #FF211C;
}

*,
::after,
::before {
    box-sizing: border-box;
}

/* *:last-child {
margin-bottom: 0;
} */
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    font-family: 'Unbounded', sans-serif;
}

ul,
ol,
li {
    margin: 0;
    padding: 0;
    color: inherit;
    font-family: 'Unbounded', sans-serif;
}

a {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    font-family: 'Unbounded', sans-serif;
}

button,
label,
input {
    padding: 0;
    margin: 0;
    display: inline-block;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    background-color: inherit;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Unbounded', sans-serif;
}

img,
svg {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
}

button:focus {
    outline: none;
}

.container {
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    max-width: 1440px;
}

.displayNone {
    display: none !important;
}

.disabledContainer {
    pointer-events: none;
    opacity: .6;
}

.opacityContainer-5 {
    opacity: .5;
}

@keyframes float1 {
    0% { transform: translate(0, 0); }
    25% { transform: translate(-10px, 15px); }
    50% { transform: translate(0, -15px); }
    75% { transform: translate(10px, 15px); }
    100% { transform: translate(0, 0); }
}

@keyframes float2 {
    0% { transform: translate(0, 0); }
    25% { transform: translate(10px, -15px); }
    50% { transform: translate(0, -20px); }
    75% { transform: translate(-10px, 10px); }
    100% { transform: translate(0, 0); }
}

@keyframes float3 {
    0% { transform: translate(0, 0); }
    25% { transform: translate(-15px, 15px); }
    50% { transform: translate(0, -25px); }
    75% { transform: translate(15px, 15px); }
    100% { transform: translate(0, 0); }
}

@keyframes float4 {
    0% { transform: translate(0, 0); }
    25% { transform: translate(10px, -10px); }
    50% { transform: translate(0, -20px); }
    75% { transform: translate(-10px, 10px); }
    100% { transform: translate(0, 0); }
}

.float1 {
    animation: float1 7s ease-in-out infinite;
}

.float2 {
    animation: float2 6s ease-in-out infinite;
}

.float3 {
    animation: float3 5s ease-in-out infinite;
}

.float4 {
    animation: float4 7s ease-in-out infinite;
}  

@media (min-width: 960px) {
    .container {
        padding: 0 80px;
    }
}