.accordion-block-wrap {
    margin-top: 45px;
    border-bottom: 1px solid #CCC;
    padding-bottom: 15px; /* add padding */
}

.accordion-block {
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.56px;
    transition: 0.4s;
    padding-bottom: 10px;
}

.symbol {
    font-weight: bold;
    margin-left: 5px;
    color: #CCC;
    transition:  transform 0.4s ease; 
}

.accordion-block__active .symbol {
    transform: rotate(180deg); 
}

.accordion-block-wrap__active {
    /* padding-bottom: 15px;  */
}
  
.accordion-block .symbol {
    font-weight: bold;
    float: right;
    margin-left: 5px;
    font-weight: 400;
    color: #CCC;
}
  
.accordion-block__panel {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;    
    max-height: 0;
    overflow: hidden;
    color: var(--greyColor3);
    transition: max-height 0.6s ease-out;
}

.accordion-block__read-more {
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.32px;
    margin-left: auto;
    color: #CCC;
    text-decoration: underline;
    text-align: end;
}

.accordion-block__panel p {
    opacity: 0;
    transition: opacity 0.5s ease-out, transform 0.3s ease-out;
}

.accordion-block-wrap__active .accordion-block__panel p {
    opacity: 1;
    transition: opacity 1.5s ease-out, transform 0.3s ease-out;
}

@media (min-width: 640px) {
    .accordion-block {
        font-size: 36px;
    }
    .accordion-block__panel {
        font-size: 14px;
    }
    .accordion-block__read-more {
        font-size: 16px;
    }
}

@media (min-width: 960px) {
    .accordion-block {
        font-size: 48px;
    }
    .accordion-block__panel {
        font-size: 20px;
    }
}
  