/* .show-message__success {
    min-width: 250px !important;
    max-width: 100vw !important;
    color: var(--customColor) !important;
    background: #ffffff !important;
    border: 1px solid var(--accentColor);   
}

.show-message__success .MuiSvgIcon-root {
    fill: var(--accentColor) !important;
}

.show-message__error {
    min-width: 250px !important;
    max-width: 100vw !important;
    background: #fff !important;
    border: 1px solid rgb(223, 17, 17) !important;
    color: rgb(223, 17, 17) !important;
}

.show-message__error .MuiSvgIcon-root {
    fill: rgb(223, 17, 17) !important;
}

.show-message__hidden {
    opacity: 0;
} */

.show-message-overlay{
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.show-message_notification--wrap {
    width: 500px;
    height: 350px;
    background-color: #d0d0d0;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
}

.show-message_notification--wrap h2 {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 3%;
    font-weight: 700;
    color: #0F0F0F;
    margin-top: 20px;
}

.show-message-img {
    max-width: 250px;
    margin-bottom: 20px; 
}

.show-message-notification-bottom-wrap{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
}

.show-message_btn-close{
    background-color: #fff;
    color: #000;
    padding: 10px 25px;
    margin-top: 20px;
    border: none;
    cursor: pointer;
}

.show-message-text{
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 3%;
    font-weight: 400;
    margin-top: 20px;
}