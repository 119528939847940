.home-view-wrap {
    padding-top: 78px;
}

.home-view {
    
}

.home-view__main-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100dvh - 78px); 
    margin-bottom: 100px;
}

.home-view__main-text {
    max-width: 380px;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: 0.96px;
}

.home-view__main-text-dots-wrap {
    display: inline-flex;
    align-items: flex-end;
}

.home-view__main-text-dots {
    position: relative;
}

.emoji-container {
    position: absolute;
    bottom: 15px;
    left: 0px;
    transform-origin: center;
    width: 100%;
    height: 7px;
}
  
.rotating-emoji {
    margin-left: -3px;
    animation: moveDiagonal 2s linear infinite;
}

@keyframes moveDiagonal {
    0% {
        transform: translate(20%, 20%); 
    }
    50% {
        transform: translate(60%, 60%);
    }
    100% {
        transform: translate(20%, 20%);
    }
}


.home-view__sub-title {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.22px;
    color: var(--redColor);
    margin-bottom: 20px;
}

.home-view__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.22px;
    color: var(--greyColor3);
    margin-bottom: 20px;
}

.home-view__logo-img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 60px;
    width: 100%;
    margin-bottom: 200px;
}

.home-view__logo-img {
    width: 46%;
    max-width: 135px;
    height: auto;
    max-height: 61px;
}

.home-view__logo-img--with {
    width: auto;
}

.home-view__logo-achievements-img {
    width: 100%;
    max-width: 120px;
    height: auto;
}

.home-view__logo-achievements-img--max-width {
    max-width: 90px;
}

.home-view__case-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 60px;
    margin-bottom: 50px;
}

.home-view__case-img-wrap {
    position: relative;
    border: 2px solid var(--lightColor);
}

.home-view__case-img {
    width: 100%;
    max-width: 590px;
    max-height: 390px;
}

.home-view__case-img--transform {

}

.home-view__accordions-wrap {
    margin-bottom: 200px;
}

@media (min-width: 420px) {
    .home-view__main-text {
        font-size: 42px;
    }
}

@media (min-width: 640px) {
    .home-view__main-text {
        max-width: 463px;
        font-size: 59px;
        font-style: normal;
        line-height: normal;
    }
    .home-view__sub-title {
        font-size: 14px;
    }
    .home-view__text {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.4px;
    }
    .home-view__case--wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px
    }
    .home-view__case-wrap { 
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 100px;
        margin-bottom: 150px;
    }
    .home-view__case-wrap--padding{
        padding-top: 200px;
    }
    .home-view__case-img--transform {
        transform: translateY(60px);
        margin-top: 60px;
    }
    .emoji-container {
        height: 14px;
        bottom: 13px;
        left: 1px;
    }
    .rotating-emoji {
        margin: 0;
        animation: moveDiagonal 2s linear infinite;
    }
    @keyframes moveDiagonal {
        0% {
            transform: translate(0, 0);
        }
        50% {
            transform: translate(30%, 30%);
        }
        100% {
            transform: translate(0, 0);
        }
    }
}

@media (min-width: 960px) {
    .home-view__main-text {
        max-width: 700px;
        font-size: 79px;
        line-height: 120px;
    }
    .home-view__logo-img-wrap {
        justify-content: space-between;
        margin-top: 80px;
        margin-bottom: 185px;
    }
    .home-view__case-wrap {
        margin-bottom: 100px;
    } 
    .emoji-container {
        width: 100%;
        height: 18px;
        bottom: 28px;
        left: 0px;
    }
    
}

@media (min-width: 1200px) {
    .home-view__main-text {
        max-width: 875px;
    }
}

@media (min-width: 1440px) {
    .home-view__main-text {
        font-size: 96px;
    }
    .emoji-container {
        width: 100%;
        height: 23px;
        bottom: 21px;
        left: 0px;
    }
    
}